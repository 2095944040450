var moment = require('moment');

export const formatBedroom = string => {
    if (string.slice(0, 1) === '1' || string === 'Studio') {
        return '1'
    }
    else if (string.slice(0, 1) === '2') {
        return '2'
    }
    else {
        return '3+'
    }
}

export const formatDateAPI = data => {
    let date = moment(data).format('YYYY-MM-DD')
    return date;
}
export const createNewDate = data => {
    return moment(data).toDate();
}

export const formatPhoneNumber = phoneNumberString => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
}
const Format = {
    formatBedroom,
    formatDateAPI,
    formatPhoneNumber,
}
export default Format 