import request from './Axios';
// these all assume that a user is logged in, 
// login will have to be explictly written the good old fashioned way - anthony

const get = (url, userID, password) => {
	return request({
		method: 'GET',
		url,
		auth: {
			username: userID,
			password
		}
	})
}
const post = (url, userID, password, content) => {
	return request({
		method: 'POST',
		url,
		auth: {
			username: userID,
			password
		},
		data: JSON.stringify(content)
	})
}

const Delete = (url, userID, password, deleteID) => {
	return request({
		method: 'DELETE',
		url: url + deleteID,
		auth: {
			username: userID,
			password
		},
		data: JSON.stringify({})
	})
}
const update = (url, userID, password, updateID, content) => {
	return request({
		method: 'PUT',
		url: url + updateID,
		auth: {
			username: userID,
			password
		},
		data: JSON.stringify(content)
	})
}
const rest = {
	get, post, Delete, update
}

export default rest;