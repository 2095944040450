import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { getDurations } from "../Services/api";
import { AccountContext } from "./account";

// Create Context Object
export const LocationContext = createContext();

export const initialLocationState = {
  data: {
    id: 0,
    zone: {
      id: 0,
    },
    availability: {
      from_date: new Date(),
      number_of_days: 1,
      windows: [],
      morning: [],
      afternoon: [],
      evening: [],
    },
    pricing: [],
    duration: 2,
  },
  show_validation_errors: false,
  is_loading: false,
};

// Create a provider for components to consume and subscribe to changes
export const LocationContextProvider = (props) => {
  const [account] = useContext(AccountContext);

  const currentState = JSON.parse(localStorage.getItem("location"));
  const [location, setLocation] = useState(
    currentState || initialLocationState
  );

  useEffect(() => {
    localStorage.setItem("location", JSON.stringify(location));
  });

  const setDuration = useCallback(
    (durations) => {
      const duration = durations.find(
        (item) =>
          item.type === "JOB" &&
          item.category?.id === account?.data?.category?.id
      );

      if (duration) {
        setLocation((prev) => ({
          ...prev,
          data: { ...prev.data, duration: duration.duration },
        }));
      }
    },
    [account]
  );

  useEffect(() => {
    if (location.data.id && account.data.token) {
      getDurations(
        {
          location_id: location.data.id,
          token: account.data.token,
        },
        setDuration
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.data.token, location.data.id]);

  return (
    <LocationContext.Provider value={[location, setLocation]}>
      {props.children}
    </LocationContext.Provider>
  );
};
