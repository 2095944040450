import rest from "./rest";
import { bugsnagClient } from "../BugSnag";
import Format from "../Services/Format";
import moment from "moment";

export const serviceCheck = (
  data = null,
  success = () => null,
  failure = () => null
) => {
  const content = {
    postal: data.postal,
    job_category_id: data.job_category_id,
  };

  rest
    .post(`servicecheck`, null, null, content)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data &&
        response.data.locations &&
        response.data.locations.length > 0
      ) {
        const locations = response.data.locations;
        const location = locations.sort((a, b) =>
          a.distance.miles > b.distance.miles ? 1 : -1
        )[0];
        success(location);
      } else {
        bugsnagClient.notify(
          new Error("200 response, but not correct data - serviceCheck"),
          { metaData: { ...response } }
        );
        failure(response);
      }
    })
    .catch((e) => {
      if (e.status !== 404) {
        bugsnagClient.notify(
          new Error("Error while performing API request - serviceCheck"),
          { metaData: { ...e } }
        );
      }
      failure(e);
    });
};

export const createAccountWithToken = (
  data = null,
  success = () => null,
  failure = () => null
) => {
  let account = data;

  function getCookie(name) {
    var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return v ? v[2] : null;
  }

  if (getCookie("FirstSession") !== null) {
    const session_info = JSON.parse(
      '{"' +
        decodeURIComponent(getCookie("FirstSession"))
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );
    account.tracking = {
      source: session_info.source,
      medium: session_info.medium,
      campaign: session_info.campaign,
      content: session_info.content,
      term: session_info.term,
    };
  }

  if (getCookie("_ga") !== null) {
    account.google = {
      id: getCookie("_ga"),
    };
  }

  if (getCookie("CHHJSessionId") !== null) {
    account.website_session = {
      id: getCookie("CHHJSessionId"),
    };
  }

  rest
    .post(
      `locations/${account.location.id}/accounts/tokens/generate`,
      null,
      null,
      account
    )
    .then((response) => {
      if (
        response.status === 200 &&
        response.data &&
        response.data.tokens &&
        response.data.tokens.length === 1
      ) {
        const token = response.data.tokens[0];
        success(token);
      } else {
        bugsnagClient.notify(
          new Error(
            "200 response, but not correct data - createAccountWithToken"
          ),
          { metaData: { ...response } }
        );
        failure(response);
      }
    })
    .catch((e) => {
      bugsnagClient.notify(
        new Error(
          "Error while performing API request - createAccountWithToken"
        ),
        { metaData: { ...e } }
      );
      failure(e);
    });
};

export const updateAccount = (
  data = null,
  success = () => null,
  failure = () => null
) => {
  const account = data;
  rest
    .update(
      `locations/${account.location.id}/accounts/${account.id}?token=${account.token}`,
      null,
      null,
      "",
      account
    )
    .then((response) => {
      if (
        response.status === 200 &&
        response.data &&
        response.data.accounts &&
        response.data.accounts.length > 0
      ) {
        const accountResponse = response.data.accounts[0];
        success(accountResponse);
      } else {
        bugsnagClient.notify(
          new Error("200 response, but not correct data - updateAccount"),
          { metaData: { ...response } }
        );
        failure(response);
      }
    })
    .catch((e) => {
      bugsnagClient.notify(
        new Error("Error while performing API request - updateAccount"),
        { metaData: { ...e } }
      );
      failure(e);
    });
};

export const getDurations = (
  data = null,
  success = () => null,
  failure = () => null
) => {
  const content = data;
  const urlparams = Object.entries(content)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  rest
    .get(
      `locations/${content.location_id}/settings/durations?${urlparams}`,
      null,
      null
    )
    .then((response) => {
      if (response.status === 200 && response.data && response.data.durations) {
        success(response.data.durations);
      } else {
        bugsnagClient.notify(
          new Error("200 response, but not correct data - durations"),
          { metaData: { ...response } }
        );
        failure(response);
      }
    })
    .catch((e) => {
      bugsnagClient.notify(
        new Error("Error while performing API request - durations"),
        { metaData: { ...e } }
      );
      failure(e);
    });
};

export const checkAvailability = (
  data = null,
  success = () => null,
  failure = () => null
) => {
  const content = data;

  rest
    .post(
      `locations/${content.location_id}/availability?token=${content.token}`,
      null,
      null,
      content
    )
    .then((response) => {
      if (
        response.status === 200 &&
        response.data &&
        response.data.availability
      ) {
        const availability = response.data.availability;
        success(availability);
      } else {
        bugsnagClient.notify(
          new Error("200 response, but not correct data - checkAvailability"),
          { metaData: { ...response } }
        );
        failure(response);
      }
    })
    .catch((e) => {
      bugsnagClient.notify(
        new Error("Error while performing API request - checkAvailability"),
        { metaData: { ...e } }
      );
      failure(e);
    });
};

export const checkPricing = (
  data = null,
  success = () => null,
  failure = () => null
) => {
  const content = data;
  const urlparams = Object.entries(content)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  rest
    .get(
      `locations/${content.location_id}/viewpricingbyproduct?${urlparams}`,
      null,
      null
    )
    .then((response) => {
      if (
        response.status === 200 &&
        response.data &&
        response.data.viewpricingbyproduct
      ) {
        success(response.data.viewpricingbyproduct);
      } else {
        bugsnagClient.notify(
          new Error("200 response, but not correct data - checkPricing"),
          { metaData: { ...response } }
        );
        failure(response);
      }
    })
    .catch((e) => {
      bugsnagClient.notify(
        new Error("Error while performing API request - checkPricing"),
        { metaData: { ...e } }
      );
      failure(e);
    });
};

export const getCouponCode = (
  couponCode,
  success = () => null,
  failure = () => null
) => {
  rest
    .get(`coupons/lookup?coupon_code=${couponCode}`)
    .then((response) => {
      if (response.status === 200 && response.data) {
        success(response.data);
      } else {
        bugsnagClient.notify(
          new Error("200 response, but not correct data for - coupon code"),
          { metaData: { ...response } }
        );
        failure(response);
      }
    })
    .catch((e) => {
      bugsnagClient.notify(
        new Error("Error while performing API request - getCouponCode"),
        { metaData: { ...e } }
      );
      failure(e);
    });
};

export const createAddress = (
  data = null,
  success = () => null,
  failure = () => null
) => {
  const address_data = data;
  rest
    .post(
      `locations/${address_data.location_id}/accounts/${address_data.account_id}/addresses?token=${address_data.token}`,
      null,
      null,
      address_data
    )
    .then((response) => {
      if (response.status === 200 && response.data && response.data.addresses) {
        const address = response.data.addresses[0];
        success(address);
      } else {
        bugsnagClient.notify(
          new Error("200 response, but not correct data - createAddress"),
          { metaData: { ...response } }
        );
        failure(response);
      }
    })
    .catch((e) => {
      bugsnagClient.notify(
        new Error("Error while performing API request - createAddress"),
        { metaData: { ...e } }
      );
      failure(e);
    });
};

export const createAppointment = (
  data = null,
  success = () => null,
  failure = () => null
) => {
  const appointment_data = data;

  rest
    .post(
      `locations/${appointment_data.location_id}/accounts/${appointment_data.account_id}/appointments?token=${appointment_data.token}`,
      null,
      null,
      appointment_data
    )
    .then((response) => {
      if (
        response.status === 200 &&
        response.data &&
        response.data.appointments
      ) {
        const appointment = response.data.appointments[0];
        success(appointment);
      } else {
        bugsnagClient.notify(
          new Error("200 response, but not correct data - createAppointment"),
          { metaData: { ...response } }
        );
        failure(response);
      }
    })
    .catch((e) => {
      bugsnagClient.notify(
        new Error("Error while performing API request - createAppointment"),
        { metaData: { ...e } }
      );
      failure(e);
    });
};

export const createTruckSchedule = (
  data = null,
  success = () => null,
  failure = () => null
) => {
  const schedule_data = data;

  rest
    .post(
      `locations/${schedule_data.location_id}/accounts/${schedule_data.account_id}/appointments/${schedule_data.appointment_id}/schedules?token=${schedule_data.token}`,
      null,
      null,
      schedule_data
    )
    .then((response) => {
      if (response.status === 200 && response.data && response.data.schedules) {
        const schedule = response.data.schedules[0];
        success(schedule);
      } else {
        bugsnagClient.notify(
          new Error("200 response, but not correct data - createTruckSchedule"),
          { metaData: { ...response } }
        );
        failure(response);
      }
    })
    .catch((e) => {
      bugsnagClient.notify(
        new Error("Error while performing API request - createTruckSchedule"),
        { metaData: { ...e } }
      );
      failure(e);
    });
};

export const createCallRequest = (
  data = null,
  success = () => null,
  failure = () => null
) => {
  const call_data = data;

  rest
    .post(
      `/locations/${data.location_id}/accounts/${data.account_id}/calls/requests?token=${data.token}`,
      null,
      null,
      call_data
    )
    .then((response) => {
      if (response.status === 200 && response.data && response.data.requests) {
        const call = response.data.requests[0];
        success(call);
      } else {
        bugsnagClient.notify(
          new Error("200 response, but not correct data - createCallRequest"),
          { metaData: { ...response } }
        );
        failure(response);
      }
    })
    .catch((e) => {
      bugsnagClient.notify(
        new Error("Error while performing API request - createCallRequest"),
        { metaData: { ...e } }
      );
      failure(e);
    });
};

export const calculateQuote = (
  data = null,
  success = () => null,
  failure = () => null
) => {
  const quote_data = data;

  rest
    .post(
      `locations/${quote_data.location.id}/accounts/${quote_data.account.id}/quotes/calculate/?token=${quote_data.token}`,
      null,
      null,
      quote_data
    )
    .then((response) => {
      if (response.status === 200 && response.data && response.data.quote) {
        const quote = response.data.quote[0];
        success(quote);
      } else {
        bugsnagClient.notify(
          new Error("200 response, but not correct data - calculateQuote"),
          { metaData: { ...response } }
        );
        failure(response);
      }
    })
    .catch((e) => {
      bugsnagClient.notify(
        new Error("Error while performing API request - calculateQuote"),
        { metaData: { ...e } }
      );
      failure(e);
    });
};

export const saveQuote = (
  data = null,
  success = () => null,
  failure = () => null
) => {
  const quote_data = data;

  rest
    .post(
      `locations/${quote_data.location.id}/accounts/${quote_data.account.id}/quotes?token=${quote_data.token}`,
      null,
      null,
      quote_data
    )
    .then((response) => {
      if (response.status === 200 && response.data && response.data.quotes) {
        const quote = response.data.quotes[0];
        success(quote);
      } else {
        bugsnagClient.notify(
          new Error("200 response, but not correct data - saveQuote"),
          { metaData: { ...response } }
        );
        failure(response);
      }
    })
    .catch((e) => {
      bugsnagClient.notify(
        new Error("Error while performing API request - saveQuote"),
        { metaData: { ...e } }
      );
      failure(e);
    });
};

export const getQuoteInfo = (account, appointment, location) => {
  const digestAccess = (floor, access) => {
    if (floor !== 1 && access === 0) {
      return 1;
    } else {
      return access;
    }
  };

  return {
    rooms: Format.formatBedroom(appointment.data.number_bedrooms),
    hunks: 2, // default
    // date: Format.formatDateAPI(Date.now()),
    appliances: parseInt(appointment.data.num_appliances),
    assembly_items: parseInt(appointment.data.num_assembly),
    heavy_items: parseInt(appointment.data.num_heavy_items),
    email_client: false, // default for now
    date: moment(location.data.availability.from_date).format("Y-MM-DD"),
    location: {
      id: location.data.id,
    },
    category: {
      id: 5, // its a move
    },
    type: {
      id: 1, // send 1
    },
    notes: {
      // not avaliable, but must be captured by agent in MQT
      size: "",
      readiness: "",
      logistical: "",
      special: "",
      junk: "",
      generated: "",
    },
    account: {
      id: account.data.id,
      first_name: account.data.first_name,
      last_name: account.data.last_name,
      phone: account.data.phone,
      email: account.data.email,
    },
    origin: {
      address: account.data.billing_address.address,
      address2: account.data.billing_address.address2,
      city: account.data.billing_address.city,
      state: account.data.billing_address.state,
      postal: account.data.billing_address.postal,
      country: account.data.billing_address.country,
      floor: parseInt(appointment.data.origin_floor),
      access_type: parseInt(
        digestAccess(
          appointment.data.origin_floor,
          appointment.data.origin_access_type
        )
      ),
      long_walks: parseInt(appointment.data.origin_long_walks),
    },
    destination: {
      address: appointment.data.destination_address.address,
      address2: appointment.data.destination_address.address2,
      city: appointment.data.destination_address.city,
      state: appointment.data.destination_address.state,
      postal: appointment.data.destination_address.postal,
      country: appointment.data.destination_address.country,
      floor: parseInt(appointment.data.destination_floor),
      access_type: parseInt(
        digestAccess(
          appointment.data.destination_floor,
          appointment.data.destination_access_type
        )
      ),
      long_walks: parseInt(appointment.data.destination_long_walks),
    },
    token: account.data.token,
  };
};

export const chargeCard = (
  data = null,
  success = () => null,
  failure = () => null
) => {
  const payment_data = data;

  rest
    .post(
      `locations/${payment_data.location_id}/accounts/${payment_data.account_id}/appointments/${payment_data.appointment_id}/payments/square?token=${payment_data.token}`,
      null,
      null,
      payment_data
    )
    .then((response) => {
      if (response.status === 200 && response.data && response.data.payments) {
        const payment = response.data.payments[0];
        success(payment);
      } else {
        bugsnagClient.notify(
          new Error("200 response, but not correct data - chargeCard"),
          { metaData: { ...response } }
        );
        failure(response);
      }
    })
    .catch((e) => {
      bugsnagClient.notify(
        new Error("Error while performing API request - chargeCard"),
        { metaData: { ...e } }
      );
      failure(e);
    });
};

export const lookupPartner = (
  data = null,
  success = () => null,
  failure = () => null
) => {
  const partner_data = data;

  rest
    .post(`subpartners/lookup`, null, null, partner_data)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data &&
        response.data.subpartners
      ) {
        const partner = response.data.subpartners[0];
        success(partner);
      } else {
        bugsnagClient.notify(
          new Error("200 response, but not correct data - lookupPartner"),
          { metaData: { ...response } }
        );
        failure(response);
      }
    })
    .catch((e) => {
      bugsnagClient.notify(
        new Error("Error while performing API request - lookupPartner"),
        { metaData: { ...e } }
      );
      failure(e);
    });
};
