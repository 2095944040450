import React, { useState, createContext, useEffect } from "react";

// Create Context Object
export const AccountContext = createContext();

export const initialAccountState = {
	data: {
		id: 0,
		selected_services: [],
		first_name: "",
		last_name: "",
		phone: "",
		email: "",
		billing_address: {
			address: "",
			address2: "",
			city: "",
			state: "",
			postal: "",
			country: "",
			place_id: "",
			place_address: ""
		},
		location: {
			id: 0
		},
		type: {
			id: 5
		},
		category: {
			id: 0
		},
		source: {
			id: 104
		},
		token: "",
		scorpion: {
			id: 0
		},
		sub_partner: {
			id: 0,
			discounts: []
		},
		marketing: {
			offers_opt_in: false
		}
	},
	show_validation_errors: false,
	is_loading: false	
};

// Create a provider for components to consume and subscribe to changes
export const AccountContextProvider = props => {
	const currentState = JSON.parse(localStorage.getItem("account"));
	const [account, setAccount] = useState(currentState || initialAccountState);

	useEffect(() => {
		localStorage.setItem("account", JSON.stringify(account));
	})

	return (
		<AccountContext.Provider value={[account, setAccount]}>
			{props.children}
		</AccountContext.Provider>
	);
};