// react required stuff
import React from 'react';
import 'react-notifications/lib/notifications.css';

import { Route, Redirect, Switch, withRouter } from 'react-router-dom';


import asyncComponent from './AsyncComponent';

//MODULES

const HomePage = asyncComponent(() => import("./Pages/HomePage"));
const PickUpLocation = asyncComponent(() => import("./Pages/PickUpLocation"));
const DestinationLocation = asyncComponent(() => import("./Pages/Moving/DestinationLocation"));
const ContactInformation = asyncComponent(() => import("./Pages/ContactInformation"));
const KindOfItems = asyncComponent(() => import("./Pages/KindOfItems"));
const CalendarWindows = asyncComponent(() => import("./Pages/CalendarWindows"));
const MovingCalendarWindows = asyncComponent(() => import("./Pages/Moving/MovingCalendarWindows"));
const Pricing = asyncComponent(() => import("./Pages/Pricing"));
const Confirmation = asyncComponent(() => import("./Pages/Confirmation"));
const NotServiced = asyncComponent(() => import("./Pages/NotServiced"));
const ProvideMoreDetails = asyncComponent(() => import("./Pages/Moving/ProvideMoreDetails"));
const TargetDate = asyncComponent(() => import("./Pages/Moving/TargetDate"));
const NumberBedrooms = asyncComponent(() => import("./Pages/Moving/NumberBedrooms"));
const AdditionalFactors = asyncComponent(() => import("./Pages/Moving/AdditionalFactors"));
const DwellingInformation = asyncComponent(() => import("./Pages/Moving/DwellingInformation"));
const DestinationDwellingInformation = asyncComponent(() => import("./Pages/Moving/DestinationDwellingInformation"));
const AddressIncomplete = asyncComponent(() => import("./Pages/AddressIncomplete"));
const MoveQuote = asyncComponent(() => import("./Pages/Moving/MoveQuote"));
const PrePayment = asyncComponent(() => import("./Pages/Moving/PrePayment"));
// END MODULES

export default
	<Switch>
		<Route path='/' exact render={() => <Redirect to="/get-started/" />} />
		<Route path='/get-started/' exact component={HomePage} />
		<Route path='/get-started/your-pick-up-location/' exact component={PickUpLocation} />
		<Route path='/get-started/your-destination-location/' exact component={DestinationLocation} />
		<Route path='/get-started/your-contact-information/' exact component={ContactInformation} />
		<Route path='/get-started/address-incomplete' exact component={AddressIncomplete} />
		<Route path='/get-started/kind-of-items/' exact component={KindOfItems} />
		<Route path='/get-started/calendar-windows/' exact component={CalendarWindows} />
		<Route path='/get-started/moving-calendar-windows/' exact component={MovingCalendarWindows} />
		<Route path='/get-started/truck-loads/' exact component={Pricing} />
		<Route path='/get-started/move-quote/' exact component={MoveQuote} />
		<Route path='/get-started/pre-payment/' exact component={PrePayment} />
		<Route path='/get-started/confirmation/' exact component={Confirmation} />
		<Route path='/get-started/not-serviced/' exact component={NotServiced} />
		<Route path='/get-started/provide-more-details/' exact component={ProvideMoreDetails} />
		<Route path='/get-started/target-date/' exact component={TargetDate} />
		<Route path='/get-started/number-bedrooms/' exact component={NumberBedrooms} />
		<Route path='/get-started/additional-factors/' exact component={AdditionalFactors} />
		<Route path='/get-started/dwelling-information/' exact component={DwellingInformation} />
		<Route path='/get-started/destination-dwelling-information/' exact component={DestinationDwellingInformation} />
		<Redirect from='*' to='/' />
	</Switch>