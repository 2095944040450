import React, { useState, createContext, useEffect } from "react";

// Create Context Object
export const AppointmentContext = createContext();

export const initialAppointmentState = {
	data: {
		id: 0,
		selected_items: [],
		start_date: null,
		end_date: null,
		resource: {
			id: 0
		},
		notes: {
			type_of_junk: '',
			location_of_junk: '',
			special_instructions: '',
			moving_factors: ''
		},
		destination_address: {
			address: "",
			address2: "",
			city: "",
			state: "",
			postal: "",
			country: "",
			place_id: "",
			place_address: ""
		},
		estimate_delivery_method: '',
		number_bedrooms: '',
		dwelling_type: '',
		destination_dwelling_type: '',
		purchase_order: '',
		num_assembly: 0,
		num_appliances: 0,
		num_heavy_items: 0,
		origin_floor: 0,
		origin_access_type: 1,
		origin_long_walks: 0,
		origin_long_walks_dropdown: 'a',
		destination_floor: 0,
		destination_long_walks: 0,
		destination_long_walks_dropdown: 'a',
		destination_access_type: 1,
		quote: {}
	},
	show_validation_errors: false,
	is_loading: false	
};

// Create a provider for components to consume and subscribe to changes
export const AppointmentContextProvider = props => {
	const currentState = JSON.parse(localStorage.getItem("appointment"));
	const [appointment, setAppointment] = useState(currentState || initialAppointmentState);

	useEffect(() => {
		localStorage.setItem("appointment", JSON.stringify(appointment));
	})

	return (
		<AppointmentContext.Provider value={[appointment, setAppointment]}>
			{props.children}
		</AppointmentContext.Provider>
	);
};